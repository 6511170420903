<template>
  <b-card-code
    no-body
  >
    <b-row
      class="px-2 mb-2"
    >
      <b-col
        v-if="$can('create', 'hardware-models')"
        order-md="2"
        order="1"
        class="text-right"
      >
        <b-form-group
          v-if="$can('delete', 'hardware-models')"
          label="With deleted"
          label-class="label-class"
        >
          <b-form-checkbox
            class="mr-0"
            switch
            inline
            @change="setFilterWithTrashed"
          />
        </b-form-group>
        <b-button
          v-if="$can('additional-action-delete', 'hardware-models')"
          variant="primary"
          class="mr-2"
          @click="toggleFilterOnlyTrashed"
        >
          <span v-if="this.onlyTrashed">Without deleted</span>
          <span v-else>Only deleted</span>
        </b-button>
        <b-button
          to="/hardware/models/create"
          variant="primary"
        >
          Create
        </b-button>
      </b-col>

      <b-col
        md="6"
        order-md="1"
        order="2"
      >
        <b-form @submit.prevent="searchRows()">

          <b-form-row class="align-items-end">
            <b-col>
              <feather-icon
                v-if="(searchFields && searchFields.length > 0) || (orderBy && orderBy.length > 0) || (sortedBy && sortedBy.length > 0)"
                v-b-tooltip.hover
                icon="XIcon"
                title="Reset filters"
                size="24"
                class="text-body align-middle mr-25"
                @click="resetFilter"
              />
            </b-col>
          </b-form-row>
        </b-form>
      </b-col>
    </b-row>

    <b-card-body>
      <!-- table -->
      <vue-good-table
        ref="VueGoodTable"
        :is-loading="isLoading"
        :columns="fields"
        compact-mode
        :rows="items"
        :total-rows="pagination.total"
        class="model-table"
        :row-style-class="rowStyleClassFn"
        mode="remote"
        :sort-options="{
          enabled: true,
          multipleColumns: true,
          initialSortBy: {field: orderBy, type: sortedBy}
        }"
        :pagination-options="{
          enabled: true,
          perPage: perPage,
          setCurrentPage: page
        }"
        @on-sort-change="onSortChange"
        @on-column-filter="columnFilterFn"
      >
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :disabled="!props.row.can_upload_config"
                  @click.prevent="updateUploadConfig(props.row)"
                >
                  <feather-icon
                    icon="FileIcon"
                    class="mr-50"
                  />
                  <span>Upload Config</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.config_file"
                  :href="props.row.config_file"
                  exact-path
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span>Download config file</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.dpk_file"
                  class="link-black"
                  :href="props.row.dpk_file"
                  exact-path
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span>Download build file</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('update', 'hardware-models')"
                  :disabled="!props.row.can_edit"
                  @click.prevent="toEdit(props.row.id)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!props.row.is_deleted && $can('delete', 'hardware-models')"
                  :disabled="!props.row.can_edit"
                  @click.prevent="deleteHandler(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.is_deleted && $can('delete', 'hardware-models')"
                  :disabled="!props.row.can_edit"
                  @click.prevent="deleteHandler(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Permanently delete</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.is_deleted && $can('delete', 'hardware-models')"
                  :disabled="!props.row.can_edit"
                  @click.prevent="recoverRow(props.row.id)"
                >
                  <feather-icon
                    icon="RefreshCcwIcon"
                    class="mr-50"
                  />
                  <span>Recover</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else-if="props.column.field === 'type'">
            <template v-if="props.formattedRow[props.column.field]">
              {{ props.formattedRow[props.column.field].name }}
            </template>
          </span>
          <span
            v-else-if="props.column.field === 'ports.interface'"
            v-b-tooltip.hover.left
            :title="props.row['ports'] ? props.row['ports'].map((ports) => `${ports.interface}-${ports.usage}`).join(', ') : ''"
            class="table-ports"
          >
            <template>
              {{ props.row['ports'] ? props.row['ports'].map((ports) => `${ports.interface}-${ports.usage}`).join(', ') : '-' }}
            </template>
          </span>
          <span v-else-if="props.column.field === 'manufacturer.name'">
            <template v-if="props.formattedRow[props.column.field]">
              {{ props.formattedRow[props.column.field] }}
            </template>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="perPage"
                :options="pageSizeOptions"
                class="mx-1"
                @change="perPageChanged"
              />
              <span class="text-nowrap"> of {{ pagination.total }} entries </span>
            </div>
            <div>
              <b-pagination
                v-if="pagination.last_page !== 1"
                :value="page"
                :total-rows="pagination.total"
                :per-page="perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>
    <b-modal
      id="modalSoftDelete"
      ref="modalSoftDelete"
      :title="deleteErrorPopUpInfo.title"
      centered
      ok-variant="secondary"
      ok-only
      ok-title="Cancel"
    >
      <p v-html="deleteErrorPopUpInfo.content" />
    </b-modal>
    <AddConfigModal
      :to-upload="toUpload"
      :fetch-items="fetchItems"
    />
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCardBody,
  BPagination,
  BButton,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BForm,
  BModal,
  BFormRow,
  BSpinner,
  VBTooltip,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import IndexPagesMixin from '@/mixins/IndexPages.vue'
import { VueGoodTable } from 'vue-good-table'
import AddConfigModal from '../AddConfigModal.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormCheckbox,
    BFormGroup,
    BCardCode,
    BCardBody,
    BButton,
    BPagination,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BForm,
    BSpinner,
    BModal,
    BFormRow,
    VueGoodTable,
    AddConfigModal,
  },
  mixins: [
    IndexPagesMixin,
  ],
  data() {
    return {
      isLoading: true,
      uppdateRouteName: 'hardware-models-update',

      deleteErrorPopUpInfo: {},

      toUpload: null,
      fields: [
        { field: 'id', label: 'Id' },
        {
          field: 'name',
          label: 'Name',
          filterOptions: {
            enabled: true,
            placeholder: 'Name',
            filterValue: '',
          },
        },
        {
          field: 'type',
          label: 'Type',
          filterOptions: {
            enabled: true,
            placeholder: 'Type',
            filterValue: '',
          },
        },
        {
          field: 'manufacturer.name',
          label: 'Manufacturer',
          filterOptions: {
            enabled: true,
            placeholder: 'Type',
            filterValue: '',
          },
        },
        {
          field: 'sku',
          label: 'SKU',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Sku',
            filterValue: '',
          },
        },
        {
          field: 'manufacturer_name',
          label: 'Manufacturer Name',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Manufacturer Name',
            filterValue: '',
          },
        },
        {
          field: 'system_board_model',
          label: 'System Board Model',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'System Board Model',
            filterValue: '',
          },
        },
        {
          field: 'ports.interface',
          label: 'Ports',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Ports',
            filterValue: '',
          },
        },
        {
          field: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'action-th-width',
          hidden: !this.$can('update', 'hardware-models') && !this.$can('delete', 'hardware-models'),
        },
      ],
    }
  },
  computed: {
    ...mapState('hardwareModels', {
      items: 'items',
      pagination: 'pagination',
      searchFields: 'searchFields',
      orderBy: 'orderBy',
      sortedBy: 'sortedBy',
    }),
  },
  watch: {
    items() {
      console.log('items', this.items)
    },
  },
  methods: {
    ...mapActions('hardwareModels', {
      fetchItems: 'getItems',
      deleteRecord: 'deleteItem',
      setSearchFields: 'setSearchFields',
      setOrderBy: 'setOrderBy',
      setSortedBy: 'setSortedBy',
      recoverRecord: 'recoverItem',
    }),
    async updateUploadConfig(row) {
      this.toUpload = row
    },
    ...mapMutations('hardwareTypes', ['RESET_STATE']),
    async deleteHandler(id) {
      const errorRes = await this.deleteRow(id)
      this.deleteErrorPopUpInfo = errorRes
    },
    rowStyleClassFn(row) {
      return row.is_deleted ? 'deleted-class' : ''
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .model-table {
    .deleted-class {
      .vgt-checkbox-col{
        background-color: rgba(255, 0, 0, 0.185) !important;
        input{
          display: none;
        }
      }
      .vgt-left-align {
        background-color: rgba(255, 0, 0, 0.185) !important;
      }
    }
  }
</style>
